/** work-around for making standalone builds work in older versions of Nx **/
/** TODO: Remove this when we upgrade to Nx 16.3.0+ **/
import path from 'path'
path.resolve(__dirname, '../next.config.js')

export function Index() {
  return (
    <div id="welcome">
      <h1>
        <span> Hello there, </span>
        Welcome to categories 👋
      </h1>
    </div>
  )
}

export default Index
